// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tools-bao-finance-xdai-farms-tvl-js": () => import("./../../../src/pages/tools/bao-finance-xdai-farms-tvl.js" /* webpackChunkName: "component---src-pages-tools-bao-finance-xdai-farms-tvl-js" */),
  "component---src-pages-tools-calculate-goose-lp-token-value-js": () => import("./../../../src/pages/tools/calculate-goose-lp-token-value.js" /* webpackChunkName: "component---src-pages-tools-calculate-goose-lp-token-value-js" */),
  "component---src-pages-tools-calculate-viking-lp-token-value-js": () => import("./../../../src/pages/tools/calculate-viking-lp-token-value.js" /* webpackChunkName: "component---src-pages-tools-calculate-viking-lp-token-value-js" */),
  "component---src-pages-tools-impermanent-loss-calculator-js": () => import("./../../../src/pages/tools/impermanent-loss-calculator.js" /* webpackChunkName: "component---src-pages-tools-impermanent-loss-calculator-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

